import Main from "../components/Main/Main"

const HomePage = () => {
	return (
		<>
			<Main />
		</>
	)
}

export default HomePage
