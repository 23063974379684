import Company from "../components/Company/Company"

const CompanyPage = () => {
	return (
		<>
			<Company />
		</>
	)
}

export default CompanyPage
