export const accordionData = [
	{
		id: 1,
		title: "Открытость и прозрачность в работе",
		text1:
			"Наша компания гордится тем, что поддерживает культуру открытости и честности во всех аспектах своей деятельности." +
			" Открытые отношения являются краеугольными камнями построения доверительных и прочных отношений как с нашими" +
			" сотрудниками, так и с клиентами. В нашей организации создана атмосфера, способствующая открытому" +
			" общению и сотрудничеству.",
		text2:
			"Мы считаем, что доверие заслуживается последовательными действиями и ответственностью. Придерживаясь самых высоких" +
			" стандартов профессионализма и этического поведения и стремимся превзойти ожидания во всем, что мы делаем. Следуя" +
			" политики открытости и честности, мы устанавливаем прочные и долговечные отношения.",
	},
	{
		id: 2,
		title: "Экологичность",
		text1:
			"Наша компания придает огромное значение экологическим аспектам своей деятельности. Мы активно работаем" +
			" над сокращением негативного воздействия на окружающую среду и постоянно ищем пути улучшения нашей" +
			" экологической производственной практики. Забота о природе является неотъемлемой частью нашего делового подхода.",
		text2:
			"Мы осуществляем прогрессивные меры по переработке отходов, снижению выбросов и энергопотребления." +
			" Наши экологические инициативы распространяются на все уровни компании, от выбора материалов" +
			" и технологий до вовлечения наших сотрудников и клиентов в поддержание устойчивой экологической политики." +
			" Мы гордимся тем, что вносим свой вклад в защиту природных ресурсов и оставляем экологически чистое наследие.",
	},
	{
		id: 3,
		title: "Высококвалифицированные работники",
		text1:
			"В нашей компании мы уделяем особое внимание профессиональному росту и развитию наших сотрудников. Мы гордимся тем," +
			" что у нас работают высококвалифицированные специалисты, обладающие глубокими знаниями и опытом в своих областях.",
		text2:
			"Мы ставим перед собой задачу обеспечить комфортные условия работы, способствующие развитию наших сотрудников." +
			" Мы инвестируем в обучение и профессиональное образование наших сотрудников, чтобы они могли совершенствоваться" +
			" и раскрывать свой потенциал. Мы создаем мотивирующую и поддерживающую атмосферу, которая позволяет нашим" +
			" сотрудникам чувствовать себя ценными членами нашей команды и успешно реализовывать свои профессиональные" +
			" амбиции.",
	},
	{
		id: 4,
		title: "Сотрудничество",
		text1:
			"Сотрудничество является фундаментом нашего успешного пути. Мы стремимся к налаживанию и поддержанию тесных" +
			" партнерских отношений с нашими клиентами, деловыми партнерами и поставщиками. Мы верим во" +
			" взаимовыгодное сотрудничество, в котором каждая сторона приносит свой уникальный вклад" +
			" и достигает вместе больших результатов.",
		text2:
			"Наше сотрудничество строится на принципах доверия и открытости. Мы ценим мнение и потребности наших партнеров," +
			" и всегда стремимся к обеспечению прозрачных коммуникаций. Мы готовы выслушать ваши идеи, рассмотреть " +
			"предложения и совместно искать оптимальные решения для взаимной пользы. Сотрудничество - это взаимодействие" +
			" на равных, где каждый может рассчитывать на взаимопонимание и поддержку. Мы уверены, что вместе мы сможем" +
			" достичь больших высот и преуспеть в своих усилиях.",
	},
]
