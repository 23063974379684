import { Rent } from "../components/Rent/Rent"

const RentPage = () => {
	return (
		<>
			<Rent />
		</>
	)
}

export default RentPage
