export const stats = [
   {
      value: "60+",
      title: "Выполненных проектов",
      subtitle: "В Москве и Московской области"
   },
   {
      value: "50+",
      title: "Сотрудников высшей категории",
      subtitle: "Ответственный подход к работе"
   },
   {
      value: "43+",
      title: "Собственной техники",
      subtitle: "Оперативность и без посредников"
   },
   {
      value: "8+",
      title: "Государственных заказов",
      subtitle: "Нам доверяют"
   },
]