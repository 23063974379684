import { ServicesDetails } from "../components/ServicesDetails/ServicesDetails"

const ServicesDetailsPage = () => {
	return (
		<>
			<ServicesDetails />
		</>
	)
}

export default ServicesDetailsPage
