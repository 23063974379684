import { JobDetails } from "../components/JobDetails/JobDetails"

const JobDetailsPage = () => {
	return (
		<>
			<JobDetails />
		</>
	)
}

export default JobDetailsPage
