import Projects from "../components/Projects/Projects"

const ProjectsPage = () => {
	return (
		<>
			<Projects />
		</>
	)
}

export default ProjectsPage
