import { NewsDetails } from "../components/NewsDetails/NewsDetails"

const NewsDetailsPage = () => {
	return (
		<>
			<NewsDetails />
		</>
	)
}

export default NewsDetailsPage
