import { News } from "../components/News/News"

const NewsPage = () => {
	return (
		<>
			<News />
		</>
	)
}

export default NewsPage
