import Services from "../components/Services/Services"

const ServicesPage = () => {
	return (
		<>
			<Services />
		</>
	)
}

export default ServicesPage
