import { Jobs } from "../components/Jobs/Jobs"

const JobsPage = () => {
	return (
		<>
			<Jobs />
		</>
	)
}

export default JobsPage
