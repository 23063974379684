import { ProjectsDetails } from "../components/ProjectsDetails/ProjectsDetails"

const ProjectsDetailsPage = () => {
	return (
		<>
			<ProjectsDetails />
		</>
	)
}

export default ProjectsDetailsPage
