export const Stage1 = () => {
   return (
      <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M23.4 0C23.8774 0 24.3352 0.189643 24.6728 0.527208C25.0104 0.864774 25.2 1.32261 25.2 1.8V16.29C29.3308 16.7065 33.1909 18.5376 36.1266 21.4734C39.0624 24.4091 40.8935 28.2691 41.31 32.4H55.8C56.2774 32.4 56.7352 32.5896 57.0728 32.9272C57.4104 33.2648 57.6 33.7226 57.6 34.2C57.6 34.6774 57.4104 35.1352 57.0728 35.4728C56.7352 35.8104 56.2774 36 55.8 36H41.31C40.8935 40.1308 39.0624 43.9909 36.1266 46.9266C33.1909 49.8624 29.3308 51.6935 25.2 52.11V55.8C25.2 56.2774 25.0104 56.7352 24.6728 57.0728C24.3352 57.4104 23.8774 57.6 23.4 57.6C22.9226 57.6 22.4648 57.4104 22.1272 57.0728C21.7896 56.7352 21.6 56.2774 21.6 55.8V52.11C17.4692 51.6935 13.6091 49.8624 10.6734 46.9266C7.73759 43.9909 5.90648 40.1308 5.49 36H1.8C1.32261 36 0.864774 35.8104 0.527208 35.4728C0.189643 35.1352 0 34.6774 0 34.2C0 33.7226 0.189643 33.2648 0.527208 32.9272C0.864774 32.5896 1.32261 32.4 1.8 32.4H5.49C5.90648 28.2691 7.73759 24.4091 10.6734 21.4734C13.6091 18.5376 17.4692 16.7065 21.6 16.29V1.8C21.6 1.32261 21.7896 0.864774 22.1272 0.527208C22.4648 0.189643 22.9226 0 23.4 0ZM9.1116 36C9.51151 39.173 10.9558 42.1227 13.2168 44.3844C15.4779 46.6461 18.4272 48.0912 21.6 48.492V36H9.1116ZM21.6 32.4V19.9116C18.4277 20.3123 15.4788 21.7568 13.2178 24.0178C10.9568 26.2788 9.51225 29.2277 9.1116 32.4H21.6ZM25.2 36V48.492C28.3735 48.0919 31.3236 46.6471 33.5854 44.3854C35.8471 42.1236 37.2919 39.1735 37.692 36H25.2ZM37.692 32.4C37.2912 29.2272 35.8461 26.2779 33.5844 24.0168C31.3227 21.7558 28.373 20.3115 25.2 19.9116V32.4H37.692Z" fill="#0591D9" />
      </svg>

   )
}

export const Stage2 = () => {
   return (
      <svg width="61" height="40" viewBox="0 0 61 40" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M37.9164 26.6878L35.846 28.7441H44.1206L52.3952 35.9511H8.94453L17.2262 28.7441H23.4304L21.3601 26.6879H15.1558L0.669922 39.039H60.6699L46.1911 26.6878H37.9164ZM56.6342 6.105L51.4616 0.960938L29.7329 22.5756L21.458 14.3367L16.2855 19.4808L29.7328 32.8636L56.6342 6.105Z" fill="#0591D9" />
      </svg>
   )
}

export const Stage3 = () => {
   return (
      <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M15.9648 12.5V5.24902C13.8164 6.27441 11.8958 7.60905 10.2031 9.25293C8.51042 10.8968 7.11068 12.7686 6.00391 14.8682L3.09863 13.6719C4.23796 11.4421 5.67025 9.43197 7.39551 7.6416C9.12077 5.85124 11.082 4.3457 13.2793 3.125H6.58984V0H19.0898V12.5H15.9648ZM44.6758 14.8682C43.6504 12.8988 42.3564 11.1328 40.7939 9.57031C39.2314 8.00781 37.4574 6.70573 35.4717 5.66406L36.668 2.7832C39.0117 3.98763 41.1113 5.51758 42.9668 7.37305C44.8223 9.22852 46.3604 11.3281 47.5811 13.6719L44.6758 14.8682ZM3.46484 25C3.46484 26.1068 3.54622 27.2054 3.70898 28.2959C3.87174 29.3864 4.12402 30.4606 4.46582 31.5186L1.58496 32.7148C0.754883 30.2246 0.339844 27.653 0.339844 25C0.339844 22.347 0.754883 19.7754 1.58496 17.2852L4.49023 18.4814C4.16471 19.5394 3.91243 20.6136 3.7334 21.7041C3.55436 22.7946 3.46484 23.8932 3.46484 25ZM5.97949 35.1318C7.00488 37.1012 8.30697 38.8753 9.88574 40.4541C11.4645 42.0329 13.2386 43.335 15.208 44.3604L14.0117 47.2656C11.668 46.0449 9.56836 44.5068 7.71289 42.6514C5.85742 40.7959 4.3112 38.6882 3.07422 36.3281L5.97949 35.1318ZM47.6787 33.2764L49.876 35.4736L36.2773 49.0967L29.71 42.5049L31.9072 40.3076L36.2773 44.6533L47.6787 33.2764ZM47.2148 25C47.2148 23.8932 47.1253 22.7946 46.9463 21.7041C46.7673 20.6136 46.515 19.5394 46.1895 18.4814L49.0947 17.2852C49.9248 19.7754 50.3398 22.347 50.3398 25H47.2148ZM18.8213 45.874C19.8792 46.2158 20.9535 46.4681 22.0439 46.6309C23.1344 46.7936 24.2331 46.875 25.3398 46.875V50C22.6706 50 20.0908 49.585 17.6006 48.7549L18.8213 45.874Z" fill="#0591D9" />
      </svg>
   )
}

export const Stage4 = () => {
   return (
      <svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M27.6035 12.2344V9.89062C27.6035 9.67578 27.4277 9.5 27.2129 9.5H8.46289C8.24805 9.5 8.07227 9.67578 8.07227 9.89062V12.2344C8.07227 12.4492 8.24805 12.625 8.46289 12.625H27.2129C27.4277 12.625 27.6035 12.4492 27.6035 12.2344ZM8.46289 16.5312C8.24805 16.5312 8.07227 16.707 8.07227 16.9219V19.2656C8.07227 19.4805 8.24805 19.6562 8.46289 19.6562H17.4473C17.6621 19.6562 17.8379 19.4805 17.8379 19.2656V16.9219C17.8379 16.707 17.6621 16.5312 17.4473 16.5312H8.46289ZM26.8223 22.1953C20.9971 22.1953 16.2754 26.917 16.2754 32.7422C16.2754 38.5674 20.9971 43.2891 26.8223 43.2891C32.6475 43.2891 37.3691 38.5674 37.3691 32.7422C37.3691 26.917 32.6475 22.1953 26.8223 22.1953ZM32.0713 37.9912C30.6699 39.3926 28.8047 40.1641 26.8223 40.1641C24.8398 40.1641 22.9746 39.3926 21.5732 37.9912C20.1719 36.5898 19.4004 34.7246 19.4004 32.7422C19.4004 30.7598 20.1719 28.8945 21.5732 27.4932C22.9746 26.0918 24.8398 25.3203 26.8223 25.3203C28.8047 25.3203 30.6699 26.0918 32.0713 27.4932C33.4727 28.8945 34.2441 30.7598 34.2441 32.7422C34.2441 34.7246 33.4727 36.5898 32.0713 37.9912ZM31.168 29.0312H29.0049C28.8779 29.0312 28.7607 29.0898 28.6875 29.1924L25.5869 33.4795L24.459 31.9219C24.4229 31.8716 24.3752 31.8307 24.3199 31.8026C24.2647 31.7746 24.2035 31.7602 24.1416 31.7607H21.9883C21.6709 31.7607 21.4854 32.1221 21.6709 32.3809L25.2744 37.3662C25.4307 37.5811 25.748 37.5811 25.9043 37.3662L31.4805 29.6514C31.6709 29.3926 31.4854 29.0312 31.168 29.0312ZM15.4941 38.6016H4.16602V4.22656H31.5098V21.0234C31.5098 21.2383 31.6855 21.4141 31.9004 21.4141H34.6348C34.8496 21.4141 35.0254 21.2383 35.0254 21.0234V2.27344C35.0254 1.40918 34.3271 0.710938 33.4629 0.710938H2.21289C1.34863 0.710938 0.650391 1.40918 0.650391 2.27344V40.5547C0.650391 41.4189 1.34863 42.1172 2.21289 42.1172H15.4941C15.709 42.1172 15.8848 41.9414 15.8848 41.7266V38.9922C15.8848 38.7773 15.709 38.6016 15.4941 38.6016Z" fill="#0591D9" />
      </svg>

   )
}